import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १८ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १८ वा' num='18'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे परशुरामा, जमदग्नीतनया । तुझ्या पडतो पाया । धावून यावे सहाय्या ॥ १ ॥
तू वेगवेगळ्या रूपात । तू वेगवेगळ्या अवतारात । कसा पाहू एकट्यात । तू दिसतोस अनेकात ॥ २ ॥
तूच परिक्षा घेतोस । वामन अवतार घेतोस । सर्व जग व्यापतोस । काही न शिल्लक ठेवतोस ॥ ३ ॥
तुझ्या कुठच्या कृतीस । तुझ्या कुठच्या रूपास । मी ठेवू विश्वास ? । जेणे अनुभवीन स्वस्थ चित्तास ॥ ४ ॥
नाना प्रलोभने देतोस । चित्त त्याने भुलवतोस । चित्त त्यात गुंतवतोस । तूच परावृत्त करतोस ॥ ५ ॥
वाटे मी तुझ्याजवळ । टिकू नये एक पळ । दावतोस नाना मृगजळ । वाटे मज पडावी भुरळ ॥ ६ ॥
हा काय मोठेपणा ? । फसवतो माझ्या मना । कसा तारे संतांना ? । हे न कळे मना ॥ ७ ॥
तू कसाही असणार । परी मी तत्व न सोडणार । तुला माझ्या स्थानावर । वेळोवेळी बोलवणार ॥ ८ ॥
सरस्वतीने आरंभिला सोहळा । तू यावेस भाग घ्यायला । तुझ्यामुळे सोहळा । आनंद होईल सकळा ॥ ९ ॥
वेगवेगळ्या रूपात यावे । हजेरीस वृद्धिंगत करावे । तू निश्चित यावे । फजितीस न करावे ॥ १० ॥
मूर्ख म्हणतील मला जन । तूच करणार अब्रू रक्षण । प्रत्येक भक्ताचे मन । नाही वेगळे ह्याहून ॥ ११ ॥
भक्त असू दे कुणाचा । प्रारब्ध भोग न सुटला त्याचा । जेव्हा प्रसंग येतो बाका । तेव्हा मारतो हाका ॥ १२ ॥
असाच प्रसंग बायजेवर । मुंडगावच्या एका भक्तावर । एका विवाहित स्त्रीवर । एका पतीव्रतेवर ॥ १३ ॥
प्रसंग अब्रूरक्षणाचा । एका निस्सीम भक्ताचा । तिच्या सत्वपरिक्षेचा । तिच्या चारित्र्याचा ॥ १४ ॥
तिच्या भावी जीवनाचा । तिच्या पतीव्रता धर्माचा । तिच्या सत्शीलाचा । तिच्या धर्मनिष्ठेचा ॥ १५ ॥
एका प्रसंगावर बाकीचे प्रसंग । त्यातूनच उमटणार नाना तरंग । जवळ करता सत्संग । सुसह्य होतो प्रसंग ॥ १६ ॥
जो भक्तीत तल्लीन होतो । तो सूज्ञच असतो । काळवेळ ओळखतो । त्याप्रमाणे जपून वागतो ॥ १७ ॥
नाही पाऊले जपून टाकत । सहज टाकली जातात । भक्तीने असलेला जागृत । सहजच असतो वागत ॥ १८ ॥
जो अंधारातून पुढे जातो । तो संभाळून पाऊले टाकतो । श्रद्धा असलेल्यावर भार टाकतो । तूच तार म्हणतो ॥ १९ ॥
हे झाले बायजेचे । एका नामजपात रमलेल्या स्त्रीचे । निष्ठावान स्त्रीचे । सन्मार्गाने चालणारीचे ॥ २० ॥
परी विरूद्ध जगणे दीराचे । अंधाराकडे जाणार्‍याचे । अंधारातच रमायचे । हेच त्याला आवडायचे ॥ २१ ॥
जो अंधारात चाचपडतो । तो वाटेत धडपडतो । स्वतःचा तोल घालवतो । दुसर्‍याचाही घालवतो ॥ २२ ॥
त्याला पुढचे दिसत नाही । त्याला भान रहात नाही । धडपडत पुढे जाई । धडपडणेच होई ॥ २३ ॥
विपरीत प्रसंग येतो । कपाळमोक्ष होतो । मग पश्चाताप होतो । परी निरर्थक ठरतो ॥ २४ ॥
काही सुधरती प्रसंगाने । काही सुधरती चिंतनाने । काहिंचे व्यर्थ जीणे । अनुभवाने न होती शहाणे ॥ २५ ॥
प्रारब्ध भोग भोगण्याची । ईच्छा नसते कुणाची । प्रारब्ध भोग संपण्याची । ईच्छा असते सर्वांची ॥ २६ ॥
कुणाचाही असो भक्त । प्रसंग कुणासही न टळत । जो करी परिस्थितीवर मात । तोच जीवनी ठरे श्रेष्ठ ॥ २७ ॥
मुंडगावची ती बायजा । शिवराम भुलाबाईची बायजा । समर्थांची भक्त बायजा । करत होती नामाची ये-जा ॥ २८ ॥
तिचे गुरू गजानन । जडली निष्ठा मनोमन । त्यांच्यात ती रममाण । विसरली देहभान ॥ २९ ॥
मायबाप बायजेचे । नव्हते तिच्या विचारांचे । चारचौघांसारखे वागायचे । व्यवहारीपणे जगायचे ॥ ३० ॥
वाटे प्रत्येक जोडप्यास । संतती व्हावी विनासायास । शिक्षण द्यावे संततीस । लग्न व्हावे योग्य वेळेस ॥ ३१ ॥
बायजा येता वयात । झाली ती विवाहित । रहावे लागे चालीरीतीत । ती न सुटली त्यात ॥ ३२ ॥
नवर्‍याकडे पाठवले । गर्भाधानास पाठवले । तिचे तेथे रहाणे झाले । प्रारब्धे संततीस नाकारले ॥ ३३ ॥
राहणी एकत्र कुटुंबाची । होती जरी शुद्धतेची । पुण्याई नव्हती दीराची । कृती ठरत होती पापाची ॥ ३४ ॥
तो देहसुखास भुललेला । कामवासनेने पीडलेला । स्वतःचा तोल घालवलेला । दुसर्‍याचाही घालवलेला ॥ ३५ ॥
एका असूरी आनंदास । हपापलेला त्याच कृत्यास । दुष्कृत्याचे बी पेरण्यास । निघाला तैसे करण्यास ॥ ३६ ॥
सत्वृत्ती सत्शीलास । निघाला ठार मारण्यास । विसरला काळवेळेस । विसरला नात्यागोत्यास ॥ ३७ ॥
बायजास नाना प्रलोभने दिली । तिला त्या वृत्तीची शिसारी वाटली । ती त्याच्यावर थुंकती झाली । जळो तुझी वृत्ती मेली ॥ ३८ ॥
घरातील वडील मंडळी । समोर उभी राहिली । तिने हकीकत सांगितली । परी कुणी न मान्य केली ॥ ३९ ॥
घरच्यांस न पटले । सर्वजण रागावले । उलटे तिलाच दटावले । वेगळेच अनुभवास आले ॥ ४० ॥
ताट आले दीराच्या पुढ्यात । शिकार आयतीच घरात । तो आनंदला मनात । स्वप्ने रंगवली क्षणात ॥ ४१ ॥
एका दीर भावजयीला । ज्ञान अज्ञानाची परिक्षा घ्यायला । सात्विक मलीन सामन्याला । झाली सुरूवात तोंड द्यायला ॥ ४२ ॥
बघ्यांस बोलका करायला । नव्हे त्यातून त्यांना शिकवायला । शीलाचे महत्व समजवायला । पतीव्रता धर्म कळण्याला ॥ ४३ ॥
करणी होती नीयतीची । तेथे जरूर होती तिची । तिनेच गाठ मारायची । तिनेच ती सोडवायची ॥ ४४ ॥
ज्याने गाठ मारलेली असते । त्यालाच ती लवकर सोडवता येते । दुकर सुकर होण्यास वेळ यावी लागते । एरव्ही हाक मारून येत नसते ॥ ४५ ॥
ती योग्य वेळेसच येते । आपले महत्व पटवते । परमेश्वराची करणी असते । त्यातून शहाणे व्हायचे असते ॥ ४६ ॥
एक दिवस दीर कामातूर झाला । तिच्या रुपाला भुलला । तिचा हात पकडला । तिची अब्रू घेऊ लागला ॥ ४७ ॥
ती म्हणाली दीराला । सोडा सोडा मला । तिला दरदरून घाम फुटला । भितीने थरकाप झाला ॥ ४८ ॥
जी सदा रंगलेली नामजपात । ती काय रमणार विषयात ? । दीराच्या त्या पापी कृत्यात । दीराच्या प्रलोभनात ॥ ४९ ॥
तिने केले गुरूंचे स्मरण । तुम्हीच करा माझे रक्षण । कुणी न येणार अन्य । मदतीला धावून ॥ ५० ॥
तिला प्रेरणा झाली । ती दीरास बोलती झाली । जी जी विषयात रमली । ती ती धुळीत मिळाली ॥ ५१ ॥
तुम्ही वडिलांसारखे । वागू नका भलते ऐसे । ऐसे किळसवाणी विचार कैसे ? । आळा घालण्यात हित असे ॥ ५२ ॥
नको नको ऐसा खेळ । मनी विकार बळावेल । दोघांचा संसार भंगेल । दोन घरे मोडेल ॥ ५३ ॥
धावा करता गुरूंचा । आवाज आला किंचाळण्याचा । विधात्याने हाक मारल्याचा । मार्ग होता सुटकेचा ॥ ५४ ॥
दीराचा मुलगा माडीवरून पडला । रक्तबंबाळ झालेला । खोक पडली कपाळाला । बायजा धावली हाकेला ॥ ५५ ॥
ती प्रसंगातून मुक्त झाली । शीलवती म्हणवली गेली । पतीव्रतेस वाचा फुटली । सत्वृत्तीने सत्शील ठरली ॥ ५६ ॥
दीरास चूक लक्षात आली । पश्चातापाची वेळ आली । दीराची बेअब्रू झाली । मृतप्राय स्थिती झाली ॥ ५७ ॥
एकाने आळवावे । दुसर्‍याने सुधरावे । ऐसे जीवन जगावे । ते आदर्श ठरावे ॥ ५८ ॥
तिच्या आईबापास कळले । तिला घरी परत आणले । अंतःकरण जड झाले । नियतीपुढे न काही चाले ॥ ५९ ॥
पोटचा गोळा जो असतो । तो दिल्या घरीच शोभतो । परत आणायचा नसतो । समझौता करायचा असतो ॥ ६० ॥
बायजेचे लक्ष घरात होते - नव्हते । ते नामजपातच रंगत होते । जीवनाचे सार कळले होते । तीचे वागणे खपत नव्हते ॥ ६१ ॥
ती नामजपात तल्लीन झाली । माता पित्या विरुद्ध वागू लागली । माता पिता कंटाळली । तिला पदोपदी समजाविली ॥ ६२ ॥
उपयोग होत नव्हता । मार्ग निघत नव्हता । कुणी योग्य हवा होता । गुरू शिवाय दुसरा नव्हता ॥ ६३ ॥
कुणी सुचविले । गुरूच करतील भले । गुरू गजानन कृपेमुळे । संकट निश्चित टळे ॥ ६४ ॥
सर्वांस विचार पटला । आले शेगावला । बायजा घातली गुरूचरणा । वृत्तांत सारा सांगितला ॥ ६५ ॥
हेतू मनीचा सांगितला । पुत्र व्हावा बायजेला । समर्थे मानेने नकार दिला । तिचा प्रारब्ध भोग कळवला ॥ ६६ ॥
नामजपात राहू द्या तिला । गुरूंनी आदेश दिला । परतली सर्व मुंडगावला । हताशपणे घराला ॥ ६७ ॥
बायजा झाली तल्लीन । होती गुरूंच्या अधीन । गुरूचरणी ती लीन । न जगू शके गुरूवीण ॥ ६८ ॥
मधुन मधुन शेगावला । जात होती दर्शनाला । कधी कधी पुंडलीक गेला । तिच्या सोबतीला ॥ ६९ ॥
जो चर्चेचा विषय झाला । ढोंगीपणाचा कळस झाला । भक्तीमार्ग पाण्यात बुडाला । दोघांना आता ऊत आला ॥ ७० ॥
वाटे दोघांचे दंभाचरण । गावनिंदेचे झाले कारण । हे कसले नामस्मरण ? । हे विषय सेवन ॥ ७१ ॥
माता पिता कंटाळली । वाटेल ते बोलू लागली । घराची अब्रू घालवली । कपाळ करंटी म्हणू लागली ॥ ७२ ॥
माहेरीच सासुरवास । होऊ लागला बायजेस । ऐसे एक एक नशिबास । कधी न येवो कुणास ॥ ७३ ॥
बायजा निमूटपणे सोशीत होती । दुःखाची जाणीव नव्हती । ती अगदी आनंदात होती । दुसरी कसली जाणीव नव्हती ॥ ७४ ॥
जाणीवच हरपली होती । विचार करायची जरूर नव्हती । एकाच्याच विचारात होती । गुरू चिंतनात मग्न होती ॥ ७५ ॥
माणूस जेव्हा एकात मग्न । तेव्हा न दुसरे विचार अन्य । बायजा उत्तम उदाहरण । देते कृतीत पटवून ॥ ७६ ॥
ज्याला हे चिंतन नसते । त्याला दुःख पीडा देते । जे मायबाप अनुभवत होते । बोलण्यावरून कळत होते ॥ ७७ ॥
पुन्हा एकदा मापबाप । असह्य झाला त्यांना ताप । शेगावी आले आपोआप । म्हणे समर्थ! तुम्हीच आता मायबाप ॥ ७८ ॥
ताप नाही सहन होत । मार्ग काही नाही निघत । नव्या उपाध्या घरात । बेजार जीव झाला त्यात ॥ ७९ ॥
पुंडलीक कारण उपाधीला । येतो नेहमी शेगावला । बायजेच्या सोबतीला । हीच चर्चा गावाला ॥ ८० ॥
महाराज हसले गालात । महाराज जेव्हा जेव्हा हसत । ते अज्ञानाला हसत । न दुःखाला हसत ॥ ८१ ॥
हसू आले अज्ञानाचे । प्रारब्ध भोग टळण्याचे । भलताच आरोप करण्याचे । जेणे दुःखी व्हावयाचे ॥ ८२ ॥
प्रारब्ध भोग भोगून संपतो । अन्यथा शिल्लक रहातो । पुढील जन्मी त्रास होतो । कुणी न टाळू शकतो ॥ ८३ ॥
महाराज जे बोलणार । ते त्यांना न रुचणार । निराशेने परत जाणार । आशा निराशा खेळ चालणार ॥ ८४ ॥
समर्थ म्हणाले माझे ऐका । शंका कुशंका काढू नका । जनाबाईस छळू नका । नामाशीच संबंध हिचा ॥ ८५ ॥
त्यांनी जाणले पुंडलीकास । बंधूच शोभेल बायजेस । नको भलते मनास । समर्थे सांगितले सर्वांस ॥ ८६ ॥
ऐसे बायजेस रक्षितात । असेच अनुभव येतात । अनेक तापमुक्त होतात । जे त्यांचेच होऊन रहातात ॥ ८७ ॥
असाच अनुभव भाऊ कवरास । एकदा फोड झाला त्यास । औषधोपचार न आला कामास । तीर्थ अंगाराच आला कामास ॥ ८८ ॥
भाऊस फोडव्याधीने घेरले । दुखणे विकोपास गेले । औषधोपचार झाले । परी निकामे ठरले ॥ ८९ ॥
दुखण्यास आराम पडेना । झोप स्वस्थ लागेना । जरी इलाज केले नाना । घरच्यांस काही सुचेना ॥ ९० ॥
एका पुटकुळीतून फोड झाला । ठिणगीतून निघाल्या ज्वाळा । जाळू लागल्या त्याला । असह्य वेदना झाल्या ॥ ९१ ॥
त्याला पीडा जाळत होती । इलाजाचे पाणी विझवत नव्हती । गुरूकृपेची जरूर होती । गुरू महती पटवत होती ॥ ९२ ॥
फोड असह्य होता । डोळ्यास डोळा लागत नव्हता । सोबतीला भाऊ होता । सुश्रुषा करीत होता ॥ ९३ ॥
एके रात्री बंगल्यात भाऊ झोपला । बंधूही होता सोबतीला । बैलगाडीचा आवाज आला । बंधू जागा झाला ॥ ९४ ॥
नीट आवाज ऐकता झाला । बैलगाडीस पहाता झाला । एक माणूस त्यातून उतरला । झाप्याजवळ आला ॥ ९५ ॥
मी “गजा” असे म्हणाला । महाराजे पाठवले मला । तीर्थ अंगारा द्यायला । भाऊस बरा करायला ॥ ९६ ॥
श्रद्धेने नंतर अंगारा लावला । नित्यक्रम चालू ठेवला । भाऊ व्याधीमुक्त झाला । नंतर समर्थांस भेटला ॥ ९७ ॥
भाऊकडे पहाताना । उजाळा दिला आठवणींना । “काय रे आमच्या बैलांना । चारा नाही घातला ना ?” ॥ ९८ ॥
समर्थेच तीर्थ अंगारा दिला । हे पटवले भाऊला । महाराज खूण पटवतात भक्ताला । विसरू नका तत्वाला ॥ ९९ ॥
पुढे समर्थ निघाले पंढरपुरला । एकादशीच्या वारीला । बापुना, जगू, आबा गेला । हरी पाटिल आणि पन्नास वारीला ॥ १०० ॥
नागझरीला गेले । वारीचे श्रीगणेशा केले । गोमाजीचे दर्शन घेतले । नामगजरात पुढे निघाले ॥ १०१ ॥
नामगजर होत होता । दुसर्‍याचे झेलत होता । एकेक रंगत होता । दुसर्‍यास रंगवत होता ॥ १०२ ॥
जो तो तल्लीन झालेला । घरगुती वातावरण विसरलेला । प्रपंचातून मुक्त झालेला । आनंदात डोलू लागला ॥ १०३ ॥
विठ्ठलातच रमलेला । विठ्ठलाभोवती नाचत असलेला । जणू पंढरपूरलाच असलेला । त्याच तंद्रीत रमलेला ॥ १०४ ॥
तंद्रीत पाऊल टाकत असलेला । नरदेहाचे सोने करण्यास निघालेला । दुसर्‍यास जागे करायला । जणू भजनात तल्लीन झालेला ॥ १०५ ॥
एक एक गाव सरत होते । त्यांचे बोल ऐकत होते । जागृत होत होते । त्यांच्यात मिसळत होते ॥ १०६ ॥
निरनिराळ्या गावचे । भजनात सामील व्हायचे । आनंद लुटायचे । प्रपंचताप विसरायचे ॥ १०७ ॥
उत्साहाच्या वातावरणात । दिवस होते जात । ऊन-पावसाची तमा न करत । वारीला होते जात ॥ १०८ ॥
बायका पोरे थोर लहान । अबीर, बुक्का, गुलाली रममाण । विसरायचे भूक तहान । इतुके भजनात रममाण ॥ १०९ ॥
आषाढ शुद्ध नवमीला । आले पंढरपुरला । कुकाजी पाटिलाच्या वाड्याला । उतरले मुक्कामाला ॥ ११० ॥
दुसरे दिवशी स्नान केले । दर्शनास निघाले । मुखाने भजन चाललेले । अबीर, गुलाल उधळले ॥ १११ ॥
एकादशीचा दिवस उजाडला । नदीत गेले स्नानाला । बापुना काळेही तेथे गेला । नदीत डुंबत राहिला ॥ ११२ ॥
त्याला यायला उशीर झाला । कुणी न तयार थांबायला । जो तो दर्शनास उत्सुकलेला । रांगेत उभा राहू लागला ॥ ११३ ॥
मंदीराची वाट चालू लागला । बापुना मागे तसाच राहिला । थोड्या वेळाने बापुना आला । कुणी न वाड्यात दिसले त्याला ॥ ११४ ॥
बापुना हताश झाला । स्वतःस अभागी समजला । वाड्यातुन निघाला दर्शनाला । सवंगड्यांस पाहू लागला ॥ ११५ ॥
मंदिराच्या वाटेने जाताना । धक्काबुक्की होताना । माणसे चेंगरली जाताना । दृष्य पहात होता बापुना ॥ ११६ ॥
कुणी शोधत होते । कुणी हरवत होते । भजनांचे सूर निघत होते । कानात एकत्र होत होते ॥ ११७ ॥
झांजांचा आवाज जोरात । येत होता ऐकण्यात । दुसर्‍याच्या बोलण्यात । मिसळत होता सुरात ॥ ११८ ॥
चिपळ्या मृदुंग पखवाज । काढती सारे मधुर आवाज । देई भजनाला साज । मिश्र सुरांचा तो रियाज ॥ ११९ ॥
सारे प्रयत्नात होते । वेगळ्याच आनंदात होते । दर्शनासाठी उत्सुक होते । पुढच्यास मागे ढकलत होते ॥ १२० ॥
हार प्रसादांचे पुडे । हातात होत होते वेडे वाकडे । प्रत्येकाचे लक्ष त्याच्याकडे । तेच सांभाळण्याकडे ॥ १२१ ॥
जीवापेक्षाही जास्त । काळजी घेत होता त्यात । जो तो रेटारेटीत । आपण आपल्यांस संभाळत ॥ १२२ ॥
वेड्या वाकड्या रांगेत । ब्राह्मणांचा वशिला त्यात । मागच्यांस पुढे करण्यात । गाभार्‍यात पुढे नेण्यास ॥ १२३ ॥
अशाच परिस्थितीत । बापुना काय पहाणार रांगेत ? । विठ्ठलच साठला नयनात । दुसरा कुणी न दिसे पुढ्यात ॥ १२४ ॥
विठ्ठल दर्शन आषाढी एकादशीला । तिरूपती बालाजी उत्सवाला । दर्शन असता भाग्याला । तरच सांगाल अनुभवाला ॥ १२५ ॥
परी तिकडचे चित्र वेगळे होते । मंडळींनी आधीच गर्दीत । आपणास पुढे रेटले होते । दर्शनाची उतावीळ करत होते ॥ १२६ ॥
जो तो देहाने रांगेत । विठ्ठलाच्या दर्शनात । परी मनाने बापुनात । मश्गुल त्याच्या विचारात ॥ १२७ ॥
सारे सहज घडत होते । नित्य नेहमीचेच होते । ताजे काहीच नव्हते । यात्रेच्या ठिकाणी असेच घडते ॥ १२८ ॥
यात्रेस मनाने जायचे असते । नुसत्या देहाने जायचे नसते । सर्वांना माहित नसते । माहित असल्यास दुर्लक्ष होते ॥ १२९ ॥
दर्शनाचा योग आला । तो केवळ देहाने आलेला । रेटारेटीने आलेला । न तो मनाने आलेला ॥ १३० ॥
बापुना ऐसा विषय झाला । गर्दीस पाऊस न जाणवला । जो तो बापुनात इतुका रंगला । विठ्ठल न दिसे कुणाला ॥ १३१ ॥
ते गर्दीत फेकले गेले । त्यांचे त्यांना न कळले । वरवर दर्शन झाले । त्यातच धन्य मानले ॥ १३२ ॥
बापुना आणि भजन मंडळी । वाड्यात परतली । थोड्या थोड्या अंतराने गेलेली । थोड्या थोड्या वेळाने परतली ॥ १३३ ॥
एकाच्या परतण्यात । दर्शनाची तळमळ त्यात । दर्शनाचा अधीरपणा त्यात । नशिबाला दोष देणे त्यात ॥ १३४ ॥
दुसर्‍यांचे देहाने दर्शन । मनाने परनिंदा चिंतन । ऐसे त्यांचे अंतःकरण । विठ्ठली न रममाण ॥ १३५ ॥
बापुनास सर्व बोलते झाले । काय तुझे येणे झाले ? । विठोबाचे दर्शन न झाले । नुसतेच पंढरपुर पाहिले ? ॥ १३६ ॥
बापुनाने उपोषण केले । विठ्ठलातच चित्त गुंतलेले । दर्शनावाचून राहिलेले । मनास अस्वस्थ करू लागले ॥ १३७ ॥
बापुन्याची तळमळ । पळापळास वाढे पळपळ । मनीध्यानी विठ्ठल । विठ्ठलच जाणे सकळ ॥ १३८ ॥
मनातील घड्याळ्याचे काटे । पुढे सरकत होते । नामस्मरण चालू होते । चित्त विठ्ठलातच गुंतले होते ॥ १३९ ॥
मनाच्या घड्याळाचा गजर । विधात्याने ठरविलेल्या वेळेवर । बिनचूक होणार । सारे वेळेनुसारच होणार ॥ १४० ॥
जो दुसर्‍यांस योग्य समयास । उपयोगी जागे करण्यास । योग्य मार्गास नेण्यास । सारी काळजी विधात्यास ॥ १४१ ॥
पहाता पहाता वेळ झाली । गजराची सुरूवात झाली । बापुन्यास उठवू लागली । नको हताश होऊ सांगू लागली ॥ १४२ ॥
आधीच लावलेल्या नामाच्या सुराला । भाग्योदयाचा सूर मिळाला । बापुनाचा उषःकाल झाला । जणू आला सांगायला ॥ १४३ ॥
महाराज स्वतःच्या जागेवरून । विठ्ठल बनले आपणहून । बापुन्याची तळमळ जाणून । विठ्ठल आला रूप घेऊन ॥ १४४ ॥
बापुना गोंधळला क्षणभर । एकदा महाराज दिगंबर । दुसरीकडे विठ्ठलाचा पितांबर । रोमांच उभे राहिले अंगभर ॥ १४५ ॥
नाना वस्त्रे नेसलेला । विविध अलंकार घातलेला । चंदनाचा टिळा लावलेला । तुळशीहार गळ्यात घातलेला ॥ १४६ ॥
डोळ्याने वाट पहात असलेला । कटीवर हात ठेवलेला । डोक्यावर मुकूट असलेला । जागच्या जागी उभा असलेला ॥ १४७ ॥
एका भक्तासाठी तिष्ठत असलेला । काळवेळ विसरलेला । देहभान विसरलेला । एकाच्यातच मग्न असलेला ॥ १४८ ॥
सभोवताली केशरचंदन । धूपाचे प्रसन्न वातावरण । सहस्त्र ज्योतींच्या तेजातुन । मुखकमळ शोभे विलक्षण ॥ १४९ ॥
परी कशाचीही पर्वा नसलेला । हेच भाव चेहेर्‍यावर उमटलेला । बापुना काळ्यात रमलेला । विठ्ठल दिसू लागला ॥ १५० ॥
डोळ्यातल्या आसवांना । पूर आला त्या क्षणा । गालांवरून ओघळताना । त्याला आवरेना ॥ १५१ ॥
सर्वांगावर रोमांच उभा राहिला । विठ्ठलच दिसू लागला । तो तंद्रीत रमला । क्षणभरात जागा झाला ॥ १५२ ॥
सभोवताली पाहू लागला । दृष्य सांगू लागला । “मंदिरातला विठ्ठल दिसला । असाच का हो आहे मंदिराला ? ᳚ ॥ १५३ ॥
सर्वजण थक्क झाले । म्हणती आपण काय पाहिले ? । विचार करू लागले । क्षुद्र आहोत वाटले ॥ १५४ ॥
दर्शनाच्या विरहाला । हृदयातल्या भावाला । सर्वांना दाखवता झाला । बापुना बोलका झाला ॥ १५५ ॥
सर्व म्हणाले समर्थांस । विठ्ठल दाखवा आम्हांस । जो दाखवला बापुन्यास । दुसरे काही नको आम्हांस ॥ १५६ ॥
महाराज सर्वांना म्हणाले । दर्शनाचे न खेळ चाले । दर्शन होते तळमळीमुळे । अंतरंगातल्या ओढीमुळे ॥ १५७ ॥
दर्शन जे देहामुळे । तेणे आनंद न मिळे । दर्शन जे मनामुळे । तेणे आनंद निश्चित मिळे ॥ १५८ ॥
आधी व्हा बापुन्यासारखे । जेणे दर्शनास न पारखे । जगात तळमळीसारखे । नाही दुसरे त्या सारखे ॥ १५९ ॥
गणामहाराज सुचवतो येथे । तळमळीच्या पाऊलाने । दर्शनाच्या ओढीने । मुक्कामावर होते जाणे ॥ १६० ॥
बापुना जैसा ह्या वारीला । गुरूकृपेस पात्र ठरला । तैसा कवठेबहादूरच्या वारकर्‍याला । गुरूकृपा योग आला ॥ १६१ ॥
आषाढ शुद्ध द्वादशीला । निघाले सर्व परतीला । ओसंडलेल्या आनंदाला । घेऊन जात होते घराला ॥ १६२ ॥
कॉलर्‍याच्या साथीला । वाटले यावे वाट्याला । तीर्थयात्रेचे भाग्य उदयाला । पतीत पावन व्हावयाला ॥ १६३ ॥
ईश्वराने ऐकले हाकेला । तिचा मनोरथ पूर्ण केला । धाडले तीर्थयात्रेला । लोकांमध्ये मिसळायला ॥ १६४ ॥
तिने लोकांचा आसरा घेतला । एक एक पछाडला गेला । तसेच कवठेबहादूरच्या वारकर्‍याला । न सोडले त्याला ॥ १६५ ॥
उलट्या जुलाब सुरू झाले । शरीरात त्राण न राहिले । कॉलर्‍याने जवळ केले । तिने त्यालाही घेरले ॥ १६६ ॥
बाकिच्यांचे डोळे । घरच्या वाटेकडे लागलेले । केवळ शरीर बरोबर आलेले । योगायोगाने जुळलेले ॥ १६७ ॥
कार्यक्रम यायचा जायचा । ठरविल्याप्रमाणे व्हायचा । त्याच्यात बदल नसायचा । एकामुळे न बदलायचा ॥ १६८ ॥
एवढेच ते शिकलेले । तेच पाळते झाले । कुजबुज करू लागले । सोयीस्कर शोधू लागले ॥ १६९ ॥
येथे असेच झाले । न झाले जगावेगळे । वारकर्‍यास साथीने पछाडले । सर्वांनी त्यास टाळले ॥ १७० ॥
म्हणती हा मरणाच्या पंथाला । जाऊ नये प्रवासाला । येथेच रहावे पंढरपुरला । बरे करावे दुखण्याला ॥ १७१ ॥
जो तो जपत होता । दुखण्यास भीत होता । प्रवासात व्यत्यय नको होता । जो तो टाळत होता ॥ १७२ ॥
सर्वजण जाण्या निघाले । एक दोघांस थांबवले । म्हणती नंतर येतील झाले । परी समर्थां न रुचले ॥ १७३ ॥
जे सर्वांना बरोबर आणणार । ते कुणास कसे वगळणार ? । जे आधार ठरणार । तेच सोबत करणार ॥ १७४ ॥
महाराज सर्वांस जवळ करायचे । कुणासही न वगळायचे । नशीब त्या वारकर्‍याचे । महाराज सोबती त्याचे ॥ १७५ ॥
त्यांनी प्रसंग ओळखला । वारकरी भाग्यवान ठरला । गुरूकृपेस पात्र ठरला । गुरूंचा आधार मिळाला ॥ १७६ ॥
महाराज जवळ गेले । त्याच्या डोक्यावर हात ठेवले । त्यास आशिर्वाद दिले । काळजीचे न कारण उरले ॥ १७७ ॥
त्याला जवळ केले । कॉलर्‍यास घालवले । काळास दूर दूर लोटले । त्याला व्याधीमुक्त केले ॥ १७८ ॥
अशी असते गुरूकृपादृष्टी । गुरूकृपेची महती । सर्वांनी ऐसी करावी भक्ती । जेणे प्राप्त गुरूकृपादृष्टी ॥ १७९ ॥
सर्वांना भरून आले । ओशाळणे झाले । चूक जाणते झाले । चूक सावरते झाले ॥ १८० ॥
सर्वजण थांबले । त्या वारकर्‍यास भरून आले । त्याचे दुखणे बरे झाले । सर्वजण शेगावी परतले ॥ १८१ ॥
बापुन्याच्या भक्तीचे । वारकर्‍याच्या कॉलर्‍याचे । विषय झाले चर्चेचे । प्रसंग पंढरपुरचे ॥ १८२ ॥
महाराज शेगावी आले । नाना तर्‍हेचे भक्त भेटले । नाना प्रश्र्न विचारते झाले । समर्थांस विनविते झाले ॥ १८३ ॥
एकदा एक कर्मठ ब्राह्मण । करण्या आला दर्शन । तेथल्या दृष्यास पाहून । मन झाले विषण्ण ॥ १८४ ॥
जो तो दिसे महाराजांत । रममाण झाला त्यात । एका नंग्या पिश्यात । होता थुंकी झेलत ॥ १८५ ॥
जो एक शब्द न जाणणार । तो काय वेद जाणणार ? । अशांचे उदो उदो झाल्यावर । ब्राह्मण का न चिडणार ? ॥ १८६ ॥
त्याला शेगावी येऊन । महाराजांना नागवे करून । महाराज अक्कलशून्य । द्यायचे होते दाखवून ॥ १८७ ॥
महाराज होते मठात । त्यांनी जाणले अंतरंगात । कुणी ब्राह्मण दारात । फजितीचा हेतू मनात ॥ १८८ ॥
ते उठले क्षणभरात । गेले ब्राह्मणाच्या पुढ्यात । म्हणे कैसे येणे शेगावात ? । काय हेतू मनात ? ॥ १८९ ॥
जे तुला मेलेले दिसते । ते जिवंत असते । वाटेत कुत्रे मेलेले नव्हते । अरे! ते जिवंत होते ॥ १९० ॥
कुत्र्याजवळ गेले । कुत्र्यास स्पर्श केले । कुत्रे लगेच उठले । ब्राह्मणास आश्चर्य वाटले ॥ १९१ ॥
ब्राह्मण होता सोवळ्यात । घागर त्याच्या हातात । पाणी भरण्याचे मनात । परी कुत्रे मेलेले मठात ॥ १९२ ॥
कुत्रे पडले होते रस्त्यात । उचलण्यास तयार होईनात । जो तो महाराजांत । हेच विचार चालले मनात ॥ १९३ ॥
कुत्रे उठताक्षणी । संशय गेला पळुनी । महाराजांचे अधिकार जाणुनी । लागला त्यांच्या चरणी ॥ १९४ ॥
ब्राह्मणाची वाणी ठरली अज्ञानी । महाराजांचा स्पर्श ठरे ज्ञानी । लागला महाराजांच्या चरणी । वाटे स्पर्शाने व्हावे ज्ञानी ॥ १९५ ॥
असा आहे गुरूस्पर्शाचा प्रभाव । दांभिकपणास न मिळे वाव । शुद्ध सात्विक पवित्र भाव । स्पर्शातच जाणवे भाव ॥ १९६ ॥
ऐसा अंतर्मनाचा भाव । जरी बोलण्याचा अभाव । तरी कृतीतून व्यक्त भाव । हाच मंत्रजपाचा प्रभाव ॥ १९७ ॥
महाराज योगी होते । खर्‍या अर्थी समर्थ होते । कृतीतून दिसत होते । जरी ते बोलत नव्हते ॥ १९८ ॥
गणामहाराज सांगे भक्तांस । शुद्ध सात्विके जगण्यास । बायजा प्रसंग ठसवा मनास । पुंडलीक ठसवा मनास ॥ १९९ ॥
मन असावे बापुन्यासारखे । कवठेबहादूरच्या वारकर्‍या सारखे । जेणे योग दर्शनाचे । जेणे योग आनंदाचे ॥ २०० ॥
गणामहाराज विनवे समर्थांस । दर्शन घडवा भक्तांस । आनंद मिळो सर्वांस । येथेच अठरावा अध्याय पूर्णत्वास ॥ २०१ ॥
 
॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य अष्टादशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
